import { getLangCode } from "src/lib/utils/common";
import { VIEW_MODES, LANGUAGES, THEMES } from "src/lib/utils/constant";
let viewMode = localStorage.getItem('viewMode');
try{
    viewMode = JSON.parse(viewMode);
}catch{
    viewMode = null;
}

let language = getLangCode();

let theme = localStorage.getItem('theme');
try{
    theme = JSON.parse(theme);
}catch{
    theme = null;
}
let accessToken = localStorage.getItem('accessToken');

const initialState = {
    callUserInfo: {},
    mpage: 1,
    viewMode: viewMode == null ? VIEW_MODES[0] : viewMode,
    language: language == null ? LANGUAGES[0]  : language,
    theme: theme == null ? THEMES[0]  : theme,
    accessToken: accessToken,
    showBetModal : false,
    betModalFirstTab : 0,
    selFixture: '123',
}
  
export const global = ( state = initialState, { type, ...rest } ) => {
    switch (type) {
        case 'setUserInfo':
            console.log('setuserinfo')
            if(rest.callUserInfo != null)
                state.callUserInfo = rest.callUserInfo;
            return { ...state, ...rest }
        case 'setMPage':
            console.log('setMPage')
            state.mpage = state.mpage + 1;
            return { ...state, ...rest }
        case 'setSelFixture':
            console.log('set selfixture')
            state.selFixture = rest.selFixture;
            return { ...state, ...rest }
        case 'changeViewMode':
            state.viewMode = rest.viewMode;
            localStorage.setItem('viewMode', JSON.stringify(state.viewMode));
            return { ...state, ...rest }
        case 'changeLanguage':
            state.language = rest.language;
            localStorage.setItem('language', rest.language);
            window.location.reload();
            return { ...state, ...rest }
        case 'changeTheme':
            state.theme = rest.theme;
            console.log(rest)
            localStorage.setItem('theme', JSON.stringify(state.theme));
            window.location.reload();
            return { ...state, ...rest }
        case 'showBetModal':
            state.showBetModal = rest.visible;
            state.betModalFirstTab = rest.firstTab;
            return { ...state }
        default:
            return {...state}
    }
}
  