let favMarkets = localStorage.getItem('favMarkets');
try{
    favMarkets = JSON.parse(favMarkets);
    if(favMarkets == null) favMarkets = [];
}catch{
    favMarkets = [];
}

let favLeagues = localStorage.getItem('favLeagues');
try{
    favLeagues = JSON.parse(favLeagues);
    if(favLeagues == null) favLeagues = [];
}catch{
    favLeagues = [];
}

let favEvents = localStorage.getItem('favEvents');
try{
    favEvents = JSON.parse(favEvents);
    if(favEvents == null) favEvents = [];
}catch{
    favEvents = [];
}

let allFavoriteEventsBySport = localStorage.getItem('allFavoriteEventsBySport');
try{
    allFavoriteEventsBySport = JSON.parse(allFavoriteEventsBySport);
    if(allFavoriteEventsBySport == null) allFavoriteEventsBySport = [];
}catch{
    allFavoriteEventsBySport = [];
}

const initialState = {
    favLeagues: favLeagues,
    favMarkets: favMarkets,
    favEvents : favEvents,
    allFavoriteEventsBySport : allFavoriteEventsBySport,
}

export const favorite = ( state = initialState, { type, ...rest } ) => {
    let isNew = true;
    switch (type) {
        case 'removeAllFav':
            state.favMarkets = [];
            state.favLeagues = [];
            state.favEvents = [];
            state.allFavoriteEventsBySport = [];
            localStorage.setItem('favMarkets', JSON.stringify(state.favMarkets));
            localStorage.setItem('favLeagues', JSON.stringify(state.favLeagues));
            localStorage.setItem('favEvents', JSON.stringify(state.favEvents));
            localStorage.setItem('allFavoriteEventsBySport', JSON.stringify(state.allFavoriteEventsBySport));
            return { ...state, ...rest }
        case 'addFavMarket':
            let newFavMarkets = [];
            state.favMarkets.forEach(function(favItem){
                if(favItem !== rest.marketId){
                    newFavMarkets.push(favItem);
                }else{
                    isNew = false;
                }
            });
            if(isNew === true){
                newFavMarkets.push(rest.marketId);
            }
            state.favMarkets = newFavMarkets;
            localStorage.setItem('favMarkets', JSON.stringify(state.favMarkets));
            return { ...state, ...rest }
        case 'addFavLeague':
            let newFavLeagues = [];
            isNew = true;
            state.favLeagues.forEach(function(favItem){
                if(favItem !== rest.leagueId){
                    newFavLeagues.push(favItem);
                }else{
                    isNew = false;
                }
            });
            if(isNew === true){
                newFavLeagues.push(rest.leagueId);
            }
            state.favLeagues = newFavLeagues;
            localStorage.setItem('favLeagues', JSON.stringify(state.favLeagues));
            return { ...state, ...rest }
        case 'addFavEvent':
            let newFavEvents = [];
            isNew = true;
            state.favEvents.forEach(function(favItem){
                if(favItem !== rest.eventId.toString()){
                    newFavEvents.push(favItem);
                }else{
                    isNew = false;
                }
            });
            if(isNew === true){
                newFavEvents.push(rest.eventId.toString());
            }
            state.favEvents = newFavEvents;
            localStorage.setItem('favEvents', JSON.stringify(state.favEvents));
            return { ...state, ...rest }
        case 'allFavoriteEventsBySport':
            state.allFavoriteEventsBySport = rest.data;
            localStorage.setItem('allFavoriteEventsBySport', JSON.stringify(state.allFavoriteEventsBySport));
            return { ...state, ...rest }
        default:
            return {...state}
    }
}
  