const initialState = {
    sportCount: {},
}
  
export const navigation = ( state = initialState, { type, ...rest } ) => {
    switch (type) {
        case 'setSportCount':
            state.sportCount = rest.data;
            return { ...state, ...rest }
        default:
            return {...state}
    }
}
  