import React from 'react';
import { SPORT_ID, SPORT_LIST } from './constant';
var LangData = require('./language.json');

var code = "en";
export const readLangCode = function(){
    var language = localStorage.getItem('language');
    try{
        code = language;
    }catch(ex){
        code = "en";
    }
    if(code == null) code = "en";
}
readLangCode();
code = "ko";

export const translate = function(key){
    try{
        return LangData[code][key];
    }catch{
    }
    return key;
}

export const lngSfx =  (code === 'en'? '': `_${code}`);

export const TR =  function(item, name){
    if(item != null){
        if(item[name + lngSfx] == null) return item[name];
        return item[name + lngSfx];
    }
};

export const newTR =  function(item, name){
    console.log(item['MarketName_ko'], item[name]);
    if(item['MarketName_ko'] == null) return item[name];
    return item['MarketName_ko'];
};

export const periodText = (sportId, periodId) => {
    try{
        //<b>현재상태 : <a>{periodText(item['SportId'], item['LiveScore']['Scoreboard']['CurrentPeriod'])}</a></b> check this later by panda
        if(sportId === SPORT_ID.AMERICAN_FOOTBALL){
            switch(periodId) {
                case 1: 
                    return '1Q';
                case 2: 
                    return '2Q';
                case 3: 
                    return '3Q';
                case 4: 
                    return '4Q';
                case 40: 
                    return 'Extra';
                case 100: 
                    return 'FT';
                case 101: 
                    return 'FT_EX';
                default:
                    return '';
            }
        }else if(sportId === SPORT_ID.BASKETBALL){
            switch(periodId) {
                case 1: 
                    return '1Q';
                case 2: 
                    return '2Q';
                case 3: 
                    return '3Q';
                case 4: 
                    return '4Q';
                case 40: 
                    return 'Extra';
                case 100: 
                    return 'FT';
                case 101: 
                    return 'FT_EX';
                case 102: 
                    return 'FT_PT';
                default:
                    return '';
            }
        }else if(sportId === SPORT_ID.VOLLEYBALL){
            switch(periodId) {
                case 1: 
                    return '1S';
                case 2: 
                    return '2S';
                case 3: 
                    return '3S';
                case 4: 
                    return '4S';
                case 5: 
                    return '5S';
                case 50: 
                    return 'GS';
                case 100: 
                    return 'FT';
                default:
                    return '';
            }
        }else if(sportId === SPORT_ID.ICEHOCKEY){
            switch(periodId) {
                case 1: 
                    return '1P';
                case 2: 
                    return '2P';
                case 3: 
                    return '3P';
                case 40: 
                    return 'Extra';
                case 100: 
                    return 'FT';
                case 101: 
                    return 'FT_EX';
                default:
                    return '';
            }
        }else if(sportId === SPORT_ID.BASEBALL){
            switch(periodId) {
                case 1: 
                    return '1Ing';
                case 2: 
                    return '2Ing';
                case 3: 
                    return '3Ing';
                case 4: 
                    return '4Ing';
                case 5: 
                    return '5Ing';
                case 6: 
                    return '6Ing';
                case 7: 
                    return '7Ing';
                case 8: 
                    return '8Ing';
                case 9: 
                    return '9Ing';
                case 40: 
                    return 'Extra';
                case 100: 
                    return 'FT';
                case 101: 
                    return 'FT_EX';
                default:
                    return '';
            }
        }else if(sportId === SPORT_ID.SOCCER){
            switch(periodId) {
                case 10: 
                    return '1H';
                case 20: 
                    return '2H';
                case 30: 
                    return 'EX_1H';
                case 35: 
                    return 'EX_2H';
                case 50: 
                    return 'PK';
                case 100: 
                    return 'FT';
                case 101: 
                    return 'FT_EX';
                case 102: 
                    return 'FT_PK';
                default:
                    return '';
            }
        }
    }catch{
    }
    return '-';
}

export const getScore = (eventInfo, period, side) => {
    try{
        let Periods = eventInfo['LiveScore']['Periods'];
        if(period === 10){
            return parseInt(Periods[0][side]['Value']) + (Periods[1] != null?parseInt(Periods[1][side]['Value']): 0);
        }
        if(period === 20){
            return parseInt(Periods[0][side]['Value']) + (Periods[1] != null?parseInt(Periods[1][side]['Value']): 0) + (Periods[2] != null?parseInt(Periods[2][side]['Value']): 0)+ (Periods[3] != null?parseInt(Periods[3][side]['Value']): 0);
        }
        return eventInfo['LiveScore']['Periods'][period]['Results'][side]['Value']
    }catch{
    }
    return '-';
}

export const getLangCode = function(){
    return code;
}
export const homeScore = function(data){
    let strTimer = '';
    try{
        let LiveScore = data['LiveScore']['Scoreboard'];
        let mins = parseInt(LiveScore['Time']) / 60;
        let period = LiveScore['CurrentPeriod'];
        let homeScore = 0, awayScore = 0;
        LiveScore['Results'].forEach(element=>{
            if(element['Position'] === "1") homeScore = element['Value'];
            if(element['Position'] === "2") awayScore = element['Value'];
        });
        return homeScore;
    }catch{
    }
    return 'N/A';
}

export const awayScore = function(data){
    let strTimer = '';
    try{
        let LiveScore = data['LiveScore']['Scoreboard'];
        let mins = parseInt(LiveScore['Time']) / 60;
        let period = LiveScore['CurrentPeriod'];
        let homeScore = 0, awayScore = 0;
        LiveScore['Results'].forEach(element=>{
            if(element['Position'] === "1") homeScore = element['Value'];
            if(element['Position'] === "2") awayScore = element['Value'];
        });
        return awayScore;
    }catch{
    }
    return 'N/A';
}


export const drawTimer = function(data){
    let strTimer = '';
    try{
        let LiveScore = data['LiveScore']['Scoreboard'];
        let mins = parseInt(LiveScore['Time']) / 60;
        let period = LiveScore['CurrentPeriod'];
        let homeScore = 0, awayScore = 0;
        LiveScore['Results'].forEach(element=>{
            if(element['Position'] === "1") homeScore = element['Value'];
            if(element['Position'] === "2") awayScore = element['Value'];
        });
        if(data['SportId'] === 6046){
            if(period === 10) strTimer = "1H " + mins +"'";
            else if(period === 20) strTimer = "2H " + (mins-45) +"'";
        }
    }catch{
    }
    return strTimer;
}

export const oddsDirection = function(item){
    let {Price, OldPrice} = item;
    Price = parseFloat(Price);
    OldPrice = parseFloat(OldPrice);
    if(Price > OldPrice){
        return <span className="eventlist_asia_fe_OddsArrow_oddsArrow eventlist_asia_fe_OddsArrow_oddsArrowUp"></span>
    }else if(Price < OldPrice){
        return <span className="eventlist_asia_fe_OddsArrow_oddsArrow eventlist_asia_fe_OddsArrow_oddsArrowDown"></span>
    }else{
        return <span className="eventlist_asia_fe_OddsArrow_oddsArrow"></span>
    }
}

export const getOddsClassName = function(item){
    let {Price, OldPrice} = item;
    Price = parseFloat(Price);
    OldPrice = parseFloat(OldPrice);
    if(Price > OldPrice){
        return "eventlist_eu_fe_MobileSelection_downSelection"
    }else if(Price < OldPrice){
        return "eventlist_eu_fe_MobileSelection_upSelection"
    }else{
        return '';
    }
}

export const getPeriodText = function(sportId, periodId){
    if(SPORT_ID.AMERICAN_FOOTBALL === parseInt(sportId)){
        if(parseInt(periodId) === 1) return '1쿼터';
        else if(parseInt(periodId) === 2) return '2쿼터';
        else if(parseInt(periodId) === 3) return '3쿼터';
        else if(parseInt(periodId) === 4) return '4쿼터';
        else if(parseInt(periodId) === 40) return '연장';
        else if(parseInt(periodId) === 100) return '풀타임';
        else if(parseInt(periodId) === 101) return '풀타임 연장포함';
    }
    else if(SPORT_ID.BADMINTON === parseInt(sportId)){
        if(parseInt(periodId) === 1) return '1st Game';
        else if(parseInt(periodId) === 2) return '2nd Game';
        else if(parseInt(periodId) === 3) return '3rd Game';
        else if(parseInt(periodId) === 4) return '4th Game';
        else if(parseInt(periodId) === 5) return '5th Game';
        else if(parseInt(periodId) === 100) return '풀타임';
    }
    else if(SPORT_ID.BANDY === parseInt(sportId)){
        if(parseInt(periodId) === 10) return '1st Half';
        else if(parseInt(periodId) === 20) return '2nd Half';
        else if(parseInt(periodId) === 40) return '연장';
        else if(parseInt(periodId) === 100) return '풀타임';
        else if(parseInt(periodId) === 101) return '풀타임 연장포함';
    }
    else if(SPORT_ID.BASEBALL === parseInt(sportId)){
        if(parseInt(periodId) === 1) return '1 이닝';
        else if(parseInt(periodId) === 2) return '2 이닝';
        else if(parseInt(periodId) === 3) return '3 이닝';
        else if(parseInt(periodId) === 4) return '4 이닝';
        else if(parseInt(periodId) === 5) return '5 이닝';
        else if(parseInt(periodId) === 6) return '6 이닝';
        else if(parseInt(periodId) === 7) return '7 이닝';
        else if(parseInt(periodId) === 8) return '8 이닝';
        else if(parseInt(periodId) === 9) return '9 이닝';
        else if(parseInt(periodId) === 40) return '연장';
        else if(parseInt(periodId) === 62) return '에러';
        else if(parseInt(periodId) === 100) return '풀타임';
        else if(parseInt(periodId) === 101) return '풀타임 연장포함';
    }
    else if(SPORT_ID.BASKETBALL === parseInt(sportId)){
        if(parseInt(periodId) === 1) return '1 쿼터';
        else if(parseInt(periodId) === 2) return '2 쿼터';
        else if(parseInt(periodId) === 3) return '3 쿼터';
        else if(parseInt(periodId) === 4) return '4 쿼터';
        else if(parseInt(periodId) === 40) return '연장';
        else if(parseInt(periodId) === 62) return '에러';
        else if(parseInt(periodId) === 100) return '풀타임';
        else if(parseInt(periodId) === 101) return '풀타임 연장포함';
    }
    else if(SPORT_ID.BEACH_VOLLEY === parseInt(sportId)){
        if(parseInt(periodId) === 1) return '1 세트';
        else if(parseInt(periodId) === 2) return '2 세트';
        else if(parseInt(periodId) === 3) return '3 세트';
        else if(parseInt(periodId) === 100) return '풀타임';
    }
    else if(SPORT_ID.CRICKET === parseInt(sportId)){
        if(parseInt(periodId) === 1) return '1 이닝';
        else if(parseInt(periodId) === 2) return '2 이닝';
        else if(parseInt(periodId) === 70) return '오버';
        else if(parseInt(periodId) === 71) return '딜러버리';
        else if(parseInt(periodId) === 100) return '풀타임';
    }
    else if(SPORT_ID.FOOTBALL === parseInt(sportId)){
        if(parseInt(periodId) === 10) return '전반전';
        else if(parseInt(periodId) === 20) return '후반전';
        else if(parseInt(periodId) === 30) return '연장 전반';
        else if(parseInt(periodId) === 35) return '연장 후반';
        else if(parseInt(periodId) === 50) return '패널티';
        else if(parseInt(periodId) === 100) return '풀타임';
        else if(parseInt(periodId) === 101) return '풀타임 연장포함';
        else if(parseInt(periodId) === 102) return '풀타임 패널티';
    }
    else if(SPORT_ID.FUTSAL === parseInt(sportId)){
        if(parseInt(periodId) === 10) return '전반전';
        else if(parseInt(periodId) === 20) return '후반전';
        else if(parseInt(periodId) === 40) return '연장';
        else if(parseInt(periodId) === 50) return '패널티';
        else if(parseInt(periodId) === 100) return '풀타임';
        else if(parseInt(periodId) === 101) return '풀타임 연장포함';
        else if(parseInt(periodId) === 102) return '풀타임 패널티';
    }
    else if(SPORT_ID.VOLLEYBALL === parseInt(sportId)){
        if(parseInt(periodId) === 1) return '1 세트';
        else if(parseInt(periodId) === 2) return '2 세트';
        else if(parseInt(periodId) === 3) return '3 세트';
        else if(parseInt(periodId) === 4) return '4 세트';
        else if(parseInt(periodId) === 5) return '5 세트';
        else if(parseInt(periodId) === 50) return '골든 세트';
        else if(parseInt(periodId) === 100) return '풀타임';
    }
}

export const displayMatch = function(betitem){
    try{
        if(betitem['Participants'] != null){
            return TR(betitem['Participants'][0], 'Name') + " vs " + TR(betitem['Participants'][1], 'Name'); 
        }else if(typeof betitem['Fixture']['Participants'] === 'string'){
            let Participants = JSON.parse(betitem['Fixture']['Participants'])
            return TR(Participants[0], 'Name') + " vs " + TR(Participants[1], 'Name'); 
        }else{
            return TR(betitem['Fixture']['Participants'][0], 'Name') + " vs " + TR(betitem['Fixture']['Participants'][1], 'Name'); 
        }
    }catch{
    }
}

export const addQuote = function(betitem){
    try{
        return '(' + betitem + ')';
    }catch{
    }
}

export const displayMatch1 = function(betitem){
    try{
        console.log('displaymatch1');
        if(betitem['Selection']['Name'] == '1'){
            if(betitem['Participants'] != null){
                return TR(betitem['Participants'][0], 'Name'); 
            }else if(typeof betitem['Fixture']['Participants'] === 'string'){
                let Participants = JSON.parse(betitem['Fixture']['Participants'])
                return TR(Participants[0], 'Name'); 
            }else{
                return TR(betitem['Fixture']['Participants'][0], 'Name'); 
            }
        }
        else if(betitem['Selection']['Name'] == '2'){
            if(betitem['Participants'] != null){
                return TR(betitem['Participants'][1], 'Name'); 
            }else if(typeof betitem['Fixture']['Participants'] === 'string'){
                let Participants = JSON.parse(betitem['Fixture']['Participants'])
                return TR(Participants[1], 'Name'); 
            }else{
                return TR(betitem['Fixture']['Participants'][1], 'Name'); 
            }
        }
        else if(betitem['Selection']['Name'] == 'x' || betitem['Selection']['Name'] == 'X'){
            return '무';
        }   
        else
        {
            return displayRunner(betitem['Selection']['Name']);
        }
    }catch{
    }
}

export const displayMatch2 = function(betitem){

    /*
            
     */

    console.log(betitem);

    try{
        if(betitem['Participants'] != null){
            if(betitem['Selection']['Name'] == '1')
                return <div><span>{TR(betitem['Participants'][0], 'Name')}{
                    (betitem['Selection']['MarketName'].includes('Handicap') === true) &&
                  <i className={"h"}></i>
                  }
      
                  {
                    betitem['Selection']['BaseLine'] && addQuote(betitem['Selection']['BaseLine'].split(' ')[0])
                  }</span>  {" vs "}  {TR(betitem['Participants'][1], 'Name')}{
                    (betitem['Selection']['MarketName'].includes('Handicap') === true) &&
                  <i className={"h"}></i>
                  }
      
                  {
                    betitem['Selection']['BaseLine'] && addQuote(betitem['Selection']['BaseLine'].split(' ')[0])
                  }</div>; 
            else if(betitem['Selection']['Name'] == '2')
                return <div><div>{TR(betitem['Participants'][0], 'Name')}{
                    (betitem['Selection']['MarketName'].includes('Handicap') === true) &&
                  <i className={"h"}></i>
                  }
      
                  {
                    betitem['Selection']['BaseLine'] && addQuote(betitem['Selection']['BaseLine'].split(' ')[0])
                  }</div>  {" vs "}  <span>{TR(betitem['Participants'][1], 'Name')}{
                    (betitem['Selection']['MarketName'].includes('Handicap') === true) &&
                  <i className={"h"}></i>
                  }
      
                  {
                    betitem['Selection']['BaseLine'] && addQuote(betitem['Selection']['BaseLine'].split(' ')[0])
                  }</span></div>; 
            else
                return <div><div>{TR(betitem['Participants'][0], 'Name')}{
                    (betitem['Selection']['MarketName'].includes('Handicap') === true) &&
                  <i className={"h"}></i>
                  }
      
                  {
                    betitem['Selection']['BaseLine'] && addQuote(betitem['Selection']['BaseLine'].split(' ')[0])
                  }</div>  {" vs "}  {TR(betitem['Participants'][1], 'Name')}{
                    (betitem['Selection']['MarketName'].includes('Handicap') === true) &&
                  <i className={"h"}></i>
                  }
      
                  {
                    betitem['Selection']['BaseLine'] && addQuote(betitem['Selection']['BaseLine'].split(' ')[0])
                  }</div>; 
        }else if(typeof betitem['Fixture']['Participants'] === 'string'){
            let Participants = JSON.parse(betitem['Fixture']['Participants']);
            if(betitem['Selection']['Name'] == '1')
                return <div><span>{TR(Participants[0], 'Name')}{
                    (betitem['Selection']['MarketName'].includes('Handicap') === true) &&
                  <i className={"h"}></i>
                  }
      
                  {
                    betitem['Selection']['BaseLine'] && addQuote(betitem['Selection']['BaseLine'].split(' ')[0])
                  }</span>  {" vs "}  {TR(Participants[1], 'Name')}{
                    (betitem['Selection']['MarketName'].includes('Handicap') === true) &&
                  <i className={"h"}></i>
                  }
      
                  {
                    betitem['Selection']['BaseLine'] && addQuote(betitem['Selection']['BaseLine'].split(' ')[0])
                  }</div>;
            else if(betitem['Selection']['Name'] == '2')
                return <div>{TR(Participants[0], 'Name')}{
                    (betitem['Selection']['MarketName'].includes('Handicap') === true) &&
                  <i className={"h"}></i>
                  }
      
                  {
                    betitem['Selection']['BaseLine'] && addQuote(betitem['Selection']['BaseLine'].split(' ')[0])
                  }  {" vs "}  <span>{TR(Participants[1], 'Name')}{
                    (betitem['Selection']['MarketName'].includes('Handicap') === true) &&
                  <i className={"h"}></i>
                  }
      
                  {
                    betitem['Selection']['BaseLine'] && addQuote(betitem['Selection']['BaseLine'].split(' ')[0])
                  }</span></div>; 
            else
                return <div>{TR(Participants[0], 'Name')}{
                    (betitem['Selection']['MarketName'].includes('Handicap') === true) &&
                  <i className={"h"}></i>
                  }
      
                  {
                    betitem['Selection']['BaseLine'] && addQuote(betitem['Selection']['BaseLine'].split(' ')[0])
                  }  {" vs "}  {TR(Participants[1], 'Name')}{
                    (betitem['Selection']['MarketName'].includes('Handicap') === true) &&
                  <i className={"h"}></i>
                  }
      
                  {
                    betitem['Selection']['BaseLine'] && addQuote(betitem['Selection']['BaseLine'].split(' ')[0])
                  }</div>;
        }else{
            if(betitem['Selection']['Name'] == '1')
                return <div><span>{TR(betitem['Fixture']['Participants'][0], 'Name')}{
                    (betitem['Selection']['MarketName'].includes('Handicap') === true) &&
                  <i className={"h"}></i>
                  }
      
                  {
                    betitem['Selection']['BaseLine'] && addQuote(betitem['Selection']['BaseLine'].split(' ')[0])
                  }</span>  {" vs "}  {TR(betitem['Fixture']['Participants'][1], 'Name')}{
                    (betitem['Selection']['MarketName'].includes('Handicap') === true) &&
                  <i className={"h"}></i>
                  }
      
                  {
                    betitem['Selection']['BaseLine'] && addQuote(betitem['Selection']['BaseLine'].split(' ')[0])
                  }</div>; 
            else if(betitem['Selection']['Name'] == '2')
                return <div>{TR(betitem['Fixture']['Participants'][0], 'Name')}{
                    (betitem['Selection']['MarketName'].includes('Handicap') === true) &&
                  <i className={"h"}></i>
                  }
      
                  {
                    betitem['Selection']['BaseLine'] && addQuote(betitem['Selection']['BaseLine'].split(' ')[0])
                  }  {" vs "}  <span>{TR(betitem['Fixture']['Participants'][1], 'Name')}{
                    (betitem['Selection']['MarketName'].includes('Handicap') === true) &&
                  <i className={"h"}></i>
                  }
      
                  {
                    betitem['Selection']['BaseLine'] && addQuote(betitem['Selection']['BaseLine'].split(' ')[0])
                  }</span></div>; 
            else
                return <div>{TR(betitem['Fixture']['Participants'][0], 'Name')}{
                    (betitem['Selection']['MarketName'].includes('Handicap') === true) &&
                  <i className={"h"}></i>
                  }
      
                  {
                    betitem['Selection']['BaseLine'] && addQuote(betitem['Selection']['BaseLine'].split(' ')[0])
                  }  {" vs "}  {TR(betitem['Fixture']['Participants'][1], 'Name')}{
                    (betitem['Selection']['MarketName'].includes('Handicap') === true) &&
                  <i className={"h"}></i>
                  }
      
                  {
                    betitem['Selection']['BaseLine'] && addQuote(betitem['Selection']['BaseLine'].split(' ')[0])
                  }</div>; 
        }
    }catch{
    }
}

export const displayMatchHome = function(betitem){
    try{
        if(betitem['Participants'] != null){
            return TR(betitem['Participants'][0], 'Name'); 
        }else if(typeof betitem['Fixture']['Participants'] === 'string'){
            let Participants = JSON.parse(betitem['Fixture']['Participants'])
            return TR(Participants[0], 'Name'); 
        }else{
            return TR(betitem['Fixture']['Participants'][0], 'Name'); 
        }
    }catch{
    }
}

export const displayMatchAway = function(betitem){
    try{
        if(betitem['Participants'] != null){
            return TR(betitem['Participants'][1], 'Name'); 
        }else if(typeof betitem['Fixture']['Participants'] === 'string'){
            let Participants = JSON.parse(betitem['Fixture']['Participants'])
            return TR(Participants[1], 'Name'); 
        }else{
            return TR(betitem['Fixture']['Participants'][1], 'Name'); 
        }
    }catch{
    }
}

export const displayRunner = function(runnerText){
    try{
        switch(runnerText){
            case '1':
                runnerText = '홈';
                break;
            case '2':
                runnerText = '원정';
                break;
            case 'X':
                runnerText = '무';
                break;
            case 'Under':
                runnerText = '언더';
                break;
            case 'Over':
                runnerText = '오버';
                break;
            default:
                break;
        }
    }catch{
    }
    return runnerText;
}

export const displayRunner1 = function(runnerText, eventInfo){
    try{
        switch(runnerText){
            case '1':
                runnerText = homeTeam(eventInfo);
                break;
            case '2':
                runnerText = awayTeam(eventInfo);
                break;
            case 'X':
                runnerText = '무';
                break;
            case 'Under':
                runnerText = '언더';
                break;
            case 'Over':
                runnerText = '오버';
                break;
            default:
                break;
        }
    }catch{
    }
    return runnerText;
}

export const homeTeam = function(betitem){
    try{
        if(betitem['Participants'] != null){
            if(typeof betitem['Participants'] === 'string')
                betitem['Participants'] = JSON.parse(betitem['Participants']);

            if(TR(betitem['Participants'][0], 'Position') === '1')
                return TR(betitem['Participants'][0], 'Name');
            if(TR(betitem['Participants'][1], 'Position') === '1')
                return TR(betitem['Participants'][1], 'Name');
        }else{
            if(typeof betitem['Fixture']['Participants'] === 'string')
                betitem['Fixture']['Participants'] = JSON.parse(betitem['Fixture']['Participants']);

            if(TR(betitem['Fixture']['Participants'][0], 'Position') === '1')
                return TR(betitem['Fixture']['Participants'][0], 'Name');
            else if(TR(betitem['Fixture']['Participants'][1], 'Position') === '1')
                return TR(betitem['Fixture']['Participants'][1], 'Name');
            else
                return TR(betitem['Fixture']['Participants'][0], 'Name');
        }
    }catch{
    }
    return 'N/A';
}

export const awayTeam = function(betitem){
    try{
        if(betitem['Participants'] != null){
            if(typeof betitem['Participants'] === 'string')
                betitem['Participants'] = JSON.parse(betitem['Participants']);

            if(TR(betitem['Participants'][0], 'Position') === '2')
                return TR(betitem['Participants'][0], 'Name');
            if(TR(betitem['Participants'][1], 'Position') === '2')
                return TR(betitem['Participants'][1], 'Name');
        }else{
            if(typeof betitem['Fixture']['Participants'] === 'string')
                betitem['Fixture']['Participants'] = JSON.parse(betitem['Fixture']['Participants']);
            
            if(TR(betitem['Fixture']['Participants'][0], 'Position') === '2')
                return TR(betitem['Fixture']['Participants'][0], 'Name');
            else if(TR(betitem['Fixture']['Participants'][1], 'Position') === '2')
                return TR(betitem['Fixture']['Participants'][1], 'Name');
            else
                return TR(betitem['Fixture']['Participants'][0], 'Name');
        }
    }catch{
    }
    return 'N/A';
}

export const displayLeague = function(betitem){
    return betitem['Fixture']['LocationName'] + " - " + betitem['Fixture']['LeagueName'];
}

export const comboBetOdds = (betList) => {
    let combinedPrice = 1;
    for(let i = 0 ; i < betList.length;  i++){
        combinedPrice *= parseFloat(betList[i]['Selection']['Price']);
    }
    if(betList.length === 0) combinedPrice = 0;
    return combinedPrice.toFixed(3);
}

function product_Range(a,b) {
    var prd = a,i = a;
   
    while (i++< b) {
      prd*=i;
    }
    return prd;
  }
  
  
export function combinations(n, r) {
    if (n==r || r==0) {
        return 1;
    } else {
        r=(r < n-r) ? n-r : r;
        return product_Range(r+1, n)/product_Range(1,n-r);
    }
}

export const getSportTitle = function(index){
    for(let i =0; i < SPORT_LIST.length; i++){
        if(parseInt(SPORT_LIST[i]['Id']) === parseInt(index)){
            return getLangCode() === 'ko'?SPORT_LIST[i]['Name_ko']: SPORT_LIST[i]['Name'];
        }
    }
    return 'N/A';
};

export const getSportEnTitle = function(index){
    for(let i =0; i < SPORT_LIST.length; i++){
        if(parseInt(SPORT_LIST[i]['Id']) === parseInt(index)){
            return SPORT_LIST[i]['Name'];
        }
    }
    return 'N/A';
};

export const removeCommas =  function(t) {
    return "-" === t || "-0" === t || "0-" === t ? "-" : (null == t && (t = ""),
    "number" == typeof t && (t = t.toString()),
    1 * t.replace(/[^\-\d\.]*/gi, ""))
};


export const addCommas = function(t) {
    t = Math.floor(t * 1000)/1000;
    return "-" === t || "-0" === t || "0-" === t ? "-" : (null == t && (t = ""),
    null == (t = removeCommas(t)) && (t = 0),
    (t *= 1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
};

export const addCommas1 = function(t) {
    if( t== 5000)
        return '오천';
    else if( t== 10000)
        return '일만';
    else if( t== 50000)
        return '오만';
    else if( t== 100000)
        return '십만';
    else if( t== 500000)
        return '오십만';
    else if( t== 1000000)
        return '백만';
    else
        return t;
};

export const displayMoney = function(t, e) {
    if(e == null){
        e = "success";
        if(parseInt(t) < 0) e = "danger";
    }
    return t? <span className={"text-" + e}><strong>{addCommas(t)}</strong></span> : <span className="text-muted">0</span>
};

export const renderConnection = function(item){
    if("PC" === item.connect_stat)
        return <span className="text-primary" style={{fontSize:"12px"}}><i className="fa fa-desktop" style={{fontSize:'18px',verticalAlign: 'middle'}} aria-hidden="true"></i> PC</span>
    else if("MOBILE" === item.connect_stat)
        return <span className="text-warning" style={{fontSize:"12px"}}><i className="fa fa-mobile" style={{fontSize:'26px', verticalAlign: 'middle'}} aria-hidden="true"></i> 모바일</span>
    else 
        return <></>
}

export const weekDayName = function (day) {
    day = parseInt(day);
    if(day  === 0) return '월요일';
    else if(day  === 1) return '화요일';
    else if(day  === 2) return '수요일';
    else if(day  === 3) return '목요일';
    else if(day  === 4) return '금요일';
    else if(day  === 5) return '토요일';
    else if(day  === 6) return '일요일';
  }