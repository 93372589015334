const initialState = {
  NavData : [],
  LiveEvents: [],
  TotalLiveCount: 0,
}

export const sockets = (state = initialState, action) => {
    switch (action.type) {
      case "allsports":
        action.data.sort((a, b)=>{
          return (a['SportId'] > b['SportId'] === true)?1:-1
        });
        let livecount = 0;
        action.data.forEach(element => {
          livecount += element['liveEventsQuantity'];
        });
        state.NavData = action.data
        state.TotalLiveCount = livecount;
        return {...state }
      case "live-events":
        state.LiveEvents = action.data
        return {...state }
      default:
        return {...state}
    }
}
