const initialState = {
    showLeftMenu: false,
    showBetSlip : false,
}
  
export const ui = ( state = initialState, { type, ...rest } ) => {
    switch (type) {
        case 'showLeftMenu':
            state.showLeftMenu = !state.showLeftMenu;
            return { ...state}
        case 'showBetSlip':
            state.showBetSlip = !state.showBetSlip;
            return { ...state}
        default:
            return {...state}
    }
}
  