import logger from "redux-logger";
import { allowedCombo, filterAllowedBets } from "src/lib/utils/betslip";
import { ACCEPT_ODDS_CHANGED_MODE } from "src/lib/utils/constant";

let selectionsId = localStorage.getItem('selectionsId');
try{
  selectionsId = JSON.parse(selectionsId);
}catch{
}

let oddsChangedMode = ACCEPT_ODDS_CHANGED_MODE.BETTER;
try{
    oddsChangedMode = localStorage.getItem('oddsChangedMode');
}catch{
}
const initialState = {
    selectionsId : selectionsId == null ? [] : selectionsId,
    betslip: [],
    currencyUnit: 'KRW',
    bonusOdds: '7',
    minStake: 5,
    maxStake : 10000,
    openBets: 0,
    needSynchronized: false,
    oddsChangedMode : oddsChangedMode,
    refreshUserInfo : false,
}
  
export const betslip = ( state = initialState, { type, ...rest } ) => {
    let newSelectionsId = [];
    switch (type) {
        case 'synchronized':
            state.needSynchronized = !state.needSynchronized ;
            return { ...state}
        case 'oddsChangedMode':
            state.oddsChangedMode = rest.value;
            localStorage.setItem('oddsChangedMode', rest.value);
            return { ...state}
        case 'openBets':
            state.openBets = rest.value;
            return { ...state}
        case 'removeBet':
            if(rest.betId === 'allplaced'){
                state.betslip = [];
                state.selectionsId = [];
            }else if(rest.betId === 'all'){
                state.betslip = [];
                state.selectionsId = [];
                
            }else{
                let newBetslip = [];
                state.betslip.forEach(function(betItem){
                if(betItem['Id'] !== rest.betId){
                    newBetslip.push(betItem);
                }
                })
                state.betslip = newBetslip;

                newSelectionsId = [];
                state.selectionsId.forEach(function(selectionId){
                if(selectionId['Id'] !== rest.betId){
                    newSelectionsId.push(selectionId);
                }
                })
                state.selectionsId = newSelectionsId;
                localStorage.setItem('selectionsId', JSON.stringify(state.selectionsId));
            }
            if(rest.noSynchronized === true)
                state.needSynchronized = !state.needSynchronized;
            else
                state.refreshUserInfo = !state.refreshUserInfo;
            return { ...state}
        case 'addBet':
            console.log('addBet ==> ', rest.data);
            //logger.warn('addBet => ', rest.data);
            let newBet = rest.data;
            newBet['isLoading'] = true;
            newBet['checked'] = true;
            newSelectionsId = [];
            let newBetslip = [];
            let isNewBet = true;
            if(state.betslip == null) state.betslip = []
            state.betslip.forEach(function(betItem){
                if(betItem['Id'] === newBet['Id']){
                    isNewBet = false;
                }else{
                    newBetslip.push(betItem);
                }
            })
            if(isNewBet === true) newBetslip.push(newBet);
            newBetslip = filterAllowedBets(newBetslip);
            state.betslip = newBetslip;

            newBetslip.forEach(function(item){
                let newSelectionId = {
                    Id              : item['Id'],
                    isCrossBet      : false,
                    isDynamicMarket : false, 
                    isBetBuilderBet : false,
                    isComboSelected : true,
                }
                newSelectionsId.push(newSelectionId);
            });
            state.selectionsId = newSelectionsId;
            localStorage.setItem('selectionsId', JSON.stringify(state.selectionsId));

            console.log('betslip => ', state.betslip);
            
            let divs = document.getElementsByTagName('script');

            if(divs[divs.length - 1].src.includes('mobile/js/script')){
                console.log('jscon',divs[divs.length - 1].src)
                const script = document.createElement("script");
                script.src = "mobile/js/script.js";
                script.async = true;
                document.body.appendChild(script);
            }

            state.needSynchronized = !state.needSynchronized;
            return { ...state, ...rest }
        case 'checkBet':
            newSelectionsId = []
            for(let i = 0; i < state.selectionsId.length; i++){
                let betItem = state.selectionsId[i];
                if(betItem['Id'] === rest.betId){
                betItem['isComboSelected'] = rest.checked;
                betItem = Object.assign({}, betItem);
                newSelectionsId[i] = betItem;
                }else{
                newSelectionsId[i] = betItem;
                }
            }
            state.selectionsId = newSelectionsId;
            localStorage.setItem('selectionsId', JSON.stringify(state.selectionsId));
            return { ...state, ...rest }
        case 'betStake':
            newSelectionsId = []
            for(let i = 0; i < state.selectionsId.length; i++){
                let betItem = state.selectionsId[i];
                if(betItem['Id'] === rest.betId){
                betItem['stake'] = rest.stake;
                betItem = Object.assign({}, betItem);
                newSelectionsId[i] = betItem;
                }else{
                newSelectionsId[i] = betItem;
                }
            }
            state.selectionsId = newSelectionsId;
            localStorage.setItem('selectionsId', JSON.stringify(state.selectionsId));
            return { ...state, ...rest }
        case 'updateBetslip':
            let newSlip = [];
            for(let i = 0; i < state.betslip.length; i++){
                let localData = state.betslip[i];
                for(let j = 0; j < rest.data.length; j++){
                    let onlineData = rest.data[j];
                    if(localData['Id'] === onlineData['Id']){
                        localData = onlineData;
                    }
                    localData['isLoading'] = false;
                }
                localData = Object.assign({}, localData);
                newSlip.push(localData);
            }
            let anotherNewSlip = [];
            for(let i = 0; i < newSlip.length; i++){
                let onlineData = newSlip[i];
                for(let j = 0; j < state.selectionsId.length; j++){
                    let betItem = state.selectionsId[j];
                    if(betItem['Id'] === onlineData['Id']){
                        anotherNewSlip.push(onlineData);
                    }
                }
            }

            state.betslip = anotherNewSlip;
            console.log('updateBetslip => ', state.betslip);
            return { ...state}
        case 'setBetslip':
            newSelectionsId = [];
            for(let i=0; i < rest.data.length; i++){
                for(let j=0; j < state.selectionsId.length; j++){
                    if(rest.data[i]['Id'] === state.selectionsId[j]['Id']){
                        newSelectionsId.push(state.selectionsId[j]);
                    }
                }
            }
            state.selectionsId = newSelectionsId;
            localStorage.setItem('selectionsId', JSON.stringify(state.selectionsId));
            if( rest.data == null ) state.betslip = [];
            else  state.betslip = rest.data;

            return { ...state}
        case 'minStake':
            state.minStake = rest.data;
            return { ...state}
        case 'maxStake':
            state.maxStake = rest.data;
            return { ...state}
        case 'currencyUnit':
            state.currencyUnit = rest.data;
            return { ...state}
        case 'bonusOdds':
            state.bonusOdds = rest.data;
                return { ...state}
        default:
            return {...state}
    }
}
  