import { ACCEPT_ODDS_CHANGED_MODE } from "src/lib/utils/constant";

let selectionsId = localStorage.getItem('selectionsId');
try{
  selectionsId = JSON.parse(selectionsId);
}catch{
}

let oddsChangedMode = ACCEPT_ODDS_CHANGED_MODE.BETTER;
try{
    oddsChangedMode = localStorage.getItem('oddsChangedMode');
}catch{
}
const initialState = {
    selectionsId : selectionsId == null ? [] : selectionsId,
    betslip: [],
    matchParlay : false,
    needSynchronized: false,
    oddsChangedMode : oddsChangedMode,
    combinableSelections: {},
    refreshUserInfo : false,
}
  
export const parlayBetslip = ( state = initialState, { type, ...rest } ) => {
    let newSelectionsId = [];
    switch (type) {
        case 'matchParlay':
            state.matchParlay = rest.checked;
            if(state.matchParlay === false){
                state.selectionsId = [];
                state.betslip = [];
                state.combinableSelections = {};
            }
            return { ...state}
        case 'combinableSelections':
            state.combinableSelections = rest.data;
            return { ...state}
        case 'addParlayBet':
            console.log('addBet => ', rest.data);
            let newBet = rest.data;
            newBet['isLoading'] = true;
            newBet['checked'] = true;
            newSelectionsId = [];
            let newBetslip = [];
            let isNewBet = true;
            state.betslip.forEach(function(betItem){
                if(betItem['Id'] === newBet['Id']){
                isNewBet = false;
                }else{
                newBetslip.push(betItem);
                }
            })
            if(isNewBet === true) newBetslip.push(newBet);
            state.betslip = newBetslip;

            let newSelectionId = {
                Id              : newBet['Id'],
                isCrossBet      : false,
                isDynamicMarket : false, 
                isBetBuilderBet : false,
                isComboSelected : true,
            }
            isNewBet = true;
            state.selectionsId.forEach(function(selectionId){
                if(selectionId['Id'] === newSelectionId['Id']){
                isNewBet = false;
                }else{
                newSelectionsId.push(selectionId);
                }
            })
            if(isNewBet === true) newSelectionsId.push(newSelectionId);
            state.selectionsId = newSelectionsId;
            state.needSynchronized = !state.needSynchronized;
            return { ...state, ...rest }
        case 'updateParlayBetslip':
            let newSlip = [];
            for(let i = 0; i < state.betslip.length; i++){
                let localData = state.betslip[i];
                for(let j = 0; j < rest.data.length; j++){
                    let onlineData = rest.data[j];
                    if(localData['Id'] === onlineData['Id']){
                        localData = onlineData;
                    }
                    localData['isLoading'] = false;
                }
                localData = Object.assign({}, localData);
                newSlip.push(localData);
            }
            let anotherNewSlip = [];
            for(let i = 0; i < newSlip.length; i++){
                let onlineData = newSlip[i];
                for(let j = 0; j < state.selectionsId.length; j++){
                    let betItem = state.selectionsId[j];
                    if(betItem['Id'] === onlineData['Id']){
                        anotherNewSlip.push(onlineData);
                    }
                }
            }

            state.betslip = anotherNewSlip;
            console.log('updateParlayBetslip => ', state.betslip);
            return { ...state}
        case 'removeParlayBet':
            console.log('removeParlayBet', rest);
            if(rest.betId === 'allplaced'){
                state.betslip = [];
                state.selectionsId = [];
            }else if(rest.betId === 'all'){
                state.betslip = [];
                state.selectionsId = [];
                
            }else{
                let newBetslip = [];
                state.betslip.forEach(function(betItem){
                if(betItem['Id'] !== rest.betId){
                    newBetslip.push(betItem);
                }
                })
                state.betslip = newBetslip;

                newSelectionsId = [];
                state.selectionsId.forEach(function(selectionId){
                if(selectionId['Id'] !== rest.betId){
                    newSelectionsId.push(selectionId);
                }
                })
                state.selectionsId = newSelectionsId;
            }
            if(rest.noSynchronized === true)
                state.needSynchronized = !state.needSynchronized;
            else 
                state.refreshUserInfo = !state.refreshUserInfo;
            return { ...state}
        default:
            return {...state}
    }
}
  