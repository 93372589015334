import React from 'react';
import { BET_TYPES, SYSTEM_BET_COUNTS, SYSTEM_BET_TYPES } from 'src/lib/utils/constant';
import { combinations, comboBetOdds } from 'src/lib/utils/common';
import moment from 'moment';
var comb = require("combinations-generator");

export const allowedCombo = (betslip) => {
    let bAllowed = true;
    let removalIdList = [];
    for(let i = 0 ; i < betslip.length;  i++){
        for(let j = 0 ; j < betslip.length;  j++){
            if(i === j) continue;
            let fixtureIdA = betslip[i]['Id'].split('-')[0];
            let fixtureIdB = betslip[j]['Id'].split('-')[0];
            if(fixtureIdA === fixtureIdB){
                bAllowed = false;
                removalIdList.push(Math.min(i, j));
            }
        }
    }
    console.log(betslip);
    console.log(removalIdList);
    let filteredList = [];
    for(let i = 0 ; i < betslip.length;  i++){
        if(removalIdList.includes(i) === false) 
            filteredList.push(betslip[i]);
    }
    return bAllowed;
}

export const filterAllowedBets = (betslip) => {
    let removalIdList = [];
    for(let i = 0 ; i < betslip.length;  i++){
        for(let j = 0 ; j < betslip.length;  j++){
            if(i === j) continue;
            let fixIdA = betslip[i]['Id'].split('-')[0];
            let fixIdB = betslip[j]['Id'].split('-')[0];
            let marketIdA = betslip[i]['Id'].split('-')[1];
            let marketIdB = betslip[j]['Id'].split('-')[1];
            if(fixIdA === fixIdB && marketIdA === marketIdB){
                removalIdList.push(Math.min(i, j));
            }
        }
    }
    let filteredList = [];
    for(let i = 0 ; i < betslip.length;  i++){
        if(removalIdList.includes(i) === false) 
            filteredList.push(betslip[i]);
    }
    return filteredList;
}

export const renderErrorMessage = function(errorReason){
    if(errorReason === 'SMALL_BALANCE') {
        return <div className="betslip_fe_WaitingBet_errorMessage">
            <div>베팅금액이 보유금을 초과했습니다.</div>
            <div className="betslip_fe_WaitingBet_line"></div>
        </div>
    }
    else if(errorReason === 'marketSuspended') {
        return <div className="betslip_fe_WaitingBet_errorMessage">
            <div>해당 마켓이 정지되었습니다.</div>
            <div className="betslip_fe_WaitingBet_line"></div>
        </div>
    }
    
}

export const renderSlipErrorMsg = function(errorReason){
    if(errorReason === 'marketSuspended') {
        return <div>
                    <div>
                        <div>
                            <div className="betslip_fe_Error_container">
                                <div className="betslip_fe_Error_messageContainer">
                                    <div className="betslip_fe_Error_infoIcon">!</div>
                                    <div className="betslip_fe_Error_text">해당 마켓이 정지되었습니다.</div>
                                </div>
                                <div className="betslip_fe_Error_buttonsContainer"></div>
                            </div>
                        </div>
                    </div>
                </div>
    }else{
        return <div>
                    <div>
                        <div>
                            <div className="betslip_fe_Error_container">
                                <div className="betslip_fe_Error_messageContainer">
                                    <div className="betslip_fe_Error_infoIcon">!</div>
                                    <div className="betslip_fe_Error_text">베팅할 수 없습니다.</div>
                                </div>
                                <div className="betslip_fe_Error_buttonsContainer"></div>
                            </div>
                        </div>
                    </div>
                </div>
    }
    
}

export const renderBetStatus = function(betitem){
    if(betitem['settled_date'] == null){
        return <li className="resultt ing">대기중</li>
    }else if(betitem['payout'] === 0)
        return <li className="resultt lose">미적중</li>
    else if(betitem['status'] === 2){ // 캐쉬아웃됨
        return <li className="resultt win">캐시아웃 완료</li>
    }else{
        return <li className="resultt win">당첨</li>
    }
}

export const renderBetStatus1 = function(betitem){
    if(betitem['settled_date'] == null){
        return <li className="resultt ing">대기중</li>
    }else if(betitem['payout'] === 0)
        return <li className="resultt lose">미적중</li>
    else if(betitem['status'] === 2){ // 캐쉬아웃됨
        return <li className="resultt win">캐시아웃 완료</li>
    }else{
        return <li className="resultt win">당첨</li>
    }
}

export const renderBetPartStatus = function(betitem){
    let Settlement = parseInt(betitem['Selection']['Settlement']);
    if(Settlement === -1){ // 취소
        return <td className="resultt exp">취소</td>
    }else if(Settlement === 1){ // 낙첨
        return <td className="resultt lose">패</td>
    }else if(Settlement === 2){ // 적중
        return <td className="resultt win">승</td>
    }else if(Settlement === 3){ // 적특 - 머니를 돌려주는 경우, 1 배당으로 처리
        return <td className="resultt exp">적특</td>
    }else if(Settlement === 4){ // 절반낙첨
        return <td className="resultt lose">하프 패</td>
    }else if(Settlement === 5){ // 절반적중
        return <td className="resultt win">하프 승</td>
    }else{
        return <td className="resultt ing">대기중</td>
    }
}

export const renderBetPartStatus1 = function(betitem){
    let Settlement = parseInt(betitem['Selection']['Settlement']);
    if(Settlement === -1){ // 취소
        return <li className="result_ing state1"><td className="resultt exp">취소</td></li>
    }else if(Settlement === 1){ // 낙첨
        return <li className="result_ing state1"><td className="resultt lose">패</td></li>
    }else if(Settlement === 2){ // 적중
        return <li className="result_ing state1"><td className="resultt win">승</td></li>
    }else if(Settlement === 3){ // 적특 - 머니를 돌려주는 경우, 1 배당으로 처리
        return <li className="result_ing state1"><td className="resultt exp">적특</td></li>
    }else if(Settlement === 4){ // 절반낙첨
        return <li className="result_ing state1"><td className="resultt lose">하프 패</td></li>
    }else if(Settlement === 5){ // 절반적중
        return <li className="result_ing state1"><td className="resultt win">하프 승</td></li>
    }else{
        return <li className="result_ing state1"><td className="resultt ing">대기중</td></li>
    }
}

export const getBetPartStatus = function(betitem){
    let Settlement = parseInt(betitem['Selection']['Settlement']);
    if(Settlement === -1){ // 취소
        return "master_fe_BetsItemStatusColorWrapper_betsItemStatusColorLost";
    }else if(Settlement === 1){ // 낙첨
        return "master_fe_BetsItemStatusColorWrapper_betsItemStatusColorLost";
    }else if(Settlement === 2){ // 적중
        return "master_fe_BetsItemStatusColorWrapper_betsItemStatusColorWin";
    }else if(Settlement === 3){ // 적특 - 머니를 돌려주는 경우, 1 배당으로 처리
        return "master_fe_BetsItemStatusColorWrapper_betsItemStatusColorWin";
    }else if(Settlement === 4){ // 절반낙첨
        return "master_fe_BetsItemStatusColorWrapper_betsItemStatusColorLost";
    }else if(Settlement === 5){ // 절반적중
        return "master_fe_BetsItemStatusColorWrapper_betsItemStatusColorWin";
    }
}

export const expectedSystemBetReturn  = function(betslip, type, stake){
    let retMoney = 0;
    if(type.startsWith('SYS_') === true){
        let r = type.split('_')[1];
        let n = type.split('_')[2];
        var iterator = comb(betslip, r);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }
    }else if(type === BET_TYPES.TRIXIE){
        var iterator = comb(betslip, 2);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 3);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }
    }else if(type === BET_TYPES.PATENT){
        for(let i =0; i < betslip.length; i++){
            retMoney += stake * parseFloat(betslip[i]['Selection']['Price']);
        }

        var iterator = comb(betslip, 2);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 3);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }
    }else if(type === BET_TYPES.YANKEE){
        var iterator = comb(betslip, 2);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 3);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 4);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }
    }else if(type === BET_TYPES.SUPER_YANKEE){
        var iterator = comb(betslip, 2);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 3);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 4);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 5);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }
    }else if(type === BET_TYPES.LUCKY15){
        for(let i =0; i < betslip.length; i++){
            retMoney += stake * parseFloat(betslip[i]['Selection']['Price']);
        }

        var iterator = comb(betslip, 2);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 3);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 4);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }
    }else if(type === BET_TYPES.LUCKY31){
        for(let i =0; i < betslip.length; i++){
            retMoney += stake * parseFloat(betslip[i]['Selection']['Price']);
        }

        var iterator = comb(betslip, 2);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 3);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 4);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 5);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }
    }else if(type === BET_TYPES.HEINZ){
        var iterator = comb(betslip, 2);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 3);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 4);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 5);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 6);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }
    }else if(type === BET_TYPES.LUCKY63){
        for(let i =0; i < betslip.length; i++){
            retMoney += stake * parseFloat(betslip[i]['Selection']['Price']);
        }

        var iterator = comb(betslip, 2);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 3);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 4);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 5);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 6);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }
    }else if(type === BET_TYPES.SUPER_HEINZ){
        var iterator = comb(betslip, 2);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 3);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 4);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 5);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 6);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 7);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }
    }else if(type === BET_TYPES.GOLIATH){
        for(let i =0; i < betslip.length; i++){
            retMoney += stake * parseFloat(betslip[i]['Selection']['Price']);
        }

        var iterator = comb(betslip, 2);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 3);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 4);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 5);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 6);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }

        iterator = comb(betslip, 7);
        for (var item of iterator) {
            let combinedPrice = 1;
            for(let i =0; i < item.length; i++){
                combinedPrice *= parseFloat(item[i]['Selection']['Price']);
            }
            retMoney += stake * combinedPrice;
        }
    }

    return retMoney.toFixed(2);
}

export const expectedSingleBetReturn = function(betitem){
    try{
        if(betitem['stake'] == null) betitem['stake'] = 0;
        return Math.floor(parseFloat(betitem['betdata'][0]['Selection']['Price']) * betitem['stake'] * 100)/100;
    }catch{

    }
    return betitem['stake'];
}

export const expectedSingleBetReturnAlt = function(betitem, stake){
    try{
        if(stake == null) stake = 0;
        return Math.floor(parseFloat(betitem['Selection']['Price']) * stake * 100)/100;
    }catch(ex){
        console.log(ex);
    }
    return stake;
}

export const expectedComboBetReturn = function(betitem){
    try{
        return Math.floor(comboBetOdds(betitem['betdata']) * betitem['stake']);
    }catch{

    }
    return betitem['stake'];
}

export const getNumberOfBets = function(data){
    if(data['type'].startsWith('SYS_') === true){
        let r = data['type'].split('_')[1];
        let n = data['type'].split('_')[2];
        return combinations(n, r);
    }else{
        return SYSTEM_BET_COUNTS[data['type']];
    }
}