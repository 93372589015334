import {combineReducers} from 'redux';
import {betslip} from './betslip';
import {parlayBetslip} from './parlayBetslip';
import {global} from './global';
import { favorite } from './favorite';
import { sockets } from './socket';
import { navigation } from './navigation';
import { ui } from './ui';

const reducers = combineReducers({
    betslip,
    parlayBetslip,
    global,
    favorite,
    sockets,
    navigation,
    ui
})
export default reducers
